import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Alert, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAxios } from '../../hooks/useAxios';
import { ApiResponse } from '../../models/ApiResponse';
import { CallItem, emptyCallItem } from '../../models/ServiceCall';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

let callItemModalOpen = (item: CallItem, contract: boolean) => {};
let callItemModalClose = () => {};

type CallItemDialogProps = {
    onClose?: () => void;

}

export default function CustomizedDialogs({ onClose }: CallItemDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [contract, setContract] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false);
  const [item, setItem] = React.useState<CallItem>(emptyCallItem(''))
  const axios = useAxios();

  callItemModalOpen = (item:CallItem, contract: boolean) => {
    setIsAdd(item.id === undefined || item.id === '')
    setItem(item);
    setOpen(true);
    setContract(contract);
  };

  callItemModalClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  

  return (
    <div>
      <BootstrapDialog
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            return false;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={callItemModalClose}
        >
          {isAdd ? "Add" : "Edit"} Call Item
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {contract && 
            <Alert severity='warning' sx={{mb: 2}}>
              This machine is <b>Under Contract!</b> Please take this into consideration when costing items
            </Alert>
          }
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <InputLabel id="itemcat">Category</InputLabel>
              <Select
                labelId="itemcat"
                value={item?.category}
                fullWidth
                onChange={(e) => {
                  setItem({
                    ...item,
                    category: e.target.value as number,
                  });
                }}
              >
                <MenuItem value={0}>PARTS</MenuItem>
                <MenuItem value={1}>LABOR</MenuItem>
                <MenuItem value={2}>OTHER</MenuItem> 
                <MenuItem value={3}>NETWORKING</MenuItem>

                <MenuItem value={4}>COPIER SERVICE</MenuItem>
                <MenuItem value={5}>PRINTER SERVICE</MenuItem>
                <MenuItem value={6}>IT REMOTE SERVICE</MenuItem> 
                <MenuItem value={7}>IT ON SITE SERVICE</MenuItem>
                <MenuItem value={8}>KFS/FMA INSTALLATION</MenuItem>

              </Select>
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                label="QTY"
                value={item.quantity}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 0.01
                  },
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    quantity: Math.round((parseFloat(e.target.value) + Number.EPSILON) * 100) / 100,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                label="Amount"
                value={item.amount}
                type="number"
                
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 0.01
                  },
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    amount: Math.round((parseFloat(e.target.value) + Number.EPSILON) * 100) / 100,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                label="Description"
                value={item.description}
                onChange={(e) => {
                  setItem({
                    ...item,
                    description: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {

              if (contract) {
                // eslint-disable-next-line no-restricted-globals
                if (!confirm('Reminder: This machine is under contract. Did you check the costs?')) {
                  return
                }
              }

              setLoading(true);

              axios
                .post<ApiResponse<CallItem[]>>(
                  "callitem/addorupdate",
                  JSON.stringify(item)
                )
                .then((res) => {
                  const data = res.data;
                  alert(data.success ? "Item saved!": "An error occurred")

                })
                .finally(() => {
                  setLoading(false);
                  callItemModalClose();
                });
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export { callItemModalClose, callItemModalOpen }
