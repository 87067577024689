import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAxios } from "../hooks/useAxios";
import { useDataProvider } from "../hooks/useDataProvider";
import { ApiResponse } from "../models/ApiResponse";
import {
  FormattedServiceCall,
  ServiceCall,
  serviceCallStatusDescription,
  serviceCallDivisionDescription,
  SERVICE_CALL_STATUS_ENUM,
  serviceCallTypeDescription,
} from "../models/ServiceCall";
import CallItemsControl from "./CallItemsControl";
import { TabPanel } from "./TabPanel";
import NotesComponent from "../pages/shared/NotesComponent";
import MeterCountComponent from "../pages/shared/MeterCountComponent";
// import ServiceHistoryModal, { serviceHistoryModalOpen } from '../pages/shared/ServiceHistoryListRender';
// import {Button} from '@mui/material'

type Props = {
  call: FormattedServiceCall;
  //allCalls: FormattedServiceCall[]
};

function ServiceCallControl({ call }: Props) {
  //allCalls

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { fetchServiceCalls, serviceCallsLoading } = useDataProvider();

  const axios = useAxios();

  return (
    <>
      {/* <Paper sx={{ m: 0, p: 0 }}>      */}
      <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
        <Tab label="Machine" />
        <Tab label="Call Details" />
        <Tab label="Call Activity" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          {/* <Grid item md={12}>
            <Chip
              size="small"
              color="primary"
              label={serviceCallDivisionDescription(call.call.division)}
            />
            {call.call.is_callback && (
              <Chip
                sx={{ ml: 1 }}
                size="small"
                color="warning"
                label="Callback"
              />
            )}
            {call.customer?.hold && (
              <Chip sx={{ ml: 1 }} size="small" color="error" label="On Hold" />
            )}
            {call.machine?.contract && (
              <Chip
                sx={{ ml: 1 }}
                size="small"
                color="success"
                label="On Contract"
              /> 
            )}
          </Grid> */}
          {/* <Grid item xs={12} md={12}>
            <u><b><i>{call.customer?.name}</i></b></u>
          </Grid> */}
          {/* <Grid item xs={12} md={12}>
            <u><b><i>Machine Status:  {serviceCallTypeDescription(call.call.call_type ? call.call.call_type : -1)}</i></b></u>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              // multiline
              // value={call.call.external_id || call.id}
              // label="Call Id"
              // fullWidth
              // disabled

              multiline
              label="Machine Status"
              InputProps={{
                readOnly: true,
                style: { fontWeight: "bold" },
              }}
              fullWidth
              variant="filled"
              value={serviceCallTypeDescription(
                call.call.call_type ? call.call.call_type : -1
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              multiline
              InputLabelProps={{ shrink: true }}
              value={call.machine?.external_id}
              label="Equipment Id"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              rows={5}
              value={call.description}
              label="Call Details"
              fullWidth
              disabled
            />
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <TextField
              multiline
              InputLabelProps={{ shrink: true }}
              value={call.machine?.serial}
              label="Serial Number"
              fullWidth
              disabled
            />
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <TextField
              multiline
              value={`${call.machine?.model} ${call.machine?.brand} ${call.machine?.make}`}
              label="Machine Model"
              fullWidth
              disabled
            />
          </Grid> */}
          {/* <Grid item xs={12} md={12}>
            <Button variant='contained' disabled={serviceCallsLoading} onClick={() => {
                serviceHistoryModalOpen(call.machine?.id ?? "", allCalls)
            }}>
                View Service History
            </Button>
          </Grid> */}
          <Grid item xs={12} md={12}>
            <InputLabel id="call-status">Status</InputLabel>
            <Select
              labelId="call-status"
              value={call.call.status}
              fullWidth
              onChange={(e) => {
                const status = e.target.value as number;

                if (status === call.call.status) {
                  return;
                }

                if (
                  // eslint-disable-next-line no-restricted-globals
                  confirm(
                    `Set Status to ${serviceCallStatusDescription(status)}`
                  )
                ) {
                  console.log("Call ID : " + call.id + " Status : " + status);
                  axios
                    .post<ApiResponse<ServiceCall>>(
                      "calls/status",
                      JSON.stringify({ id: call.id, status: status })
                    )
                    .then((res) => {
                      const data = res.data;
                      alert(data.message);
                    })
                    .catch((error) => {
                      // Handle error
                      if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(
                          "Server responded with error:",
                          error.response.data
                        );
                        console.log("Status code:", error.response.status);
                      } else if (error.request) {
                        // The request was made but no response was received
                        console.log("No response received:", error.request);
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log(
                          "Error during request setup:",
                          error.message
                        );
                      }
                    })
                    .finally(() => {
                      fetchServiceCalls();
                    });
                }
              }}
            >
              {SERVICE_CALL_STATUS_ENUM.map((e, i) => (
                <MenuItem key={i} value={i}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={12}>
            <TextField
              multiline
              rows={5}
              value={call.description}
              label="Details"
              fullWidth
              disabled
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              value={call.call?.caller || "(none provided)"}
              label="Contact Name"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              value={call.call?.caller_details || "(none provided)"}
              label="Contact Number"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              value={call.machine?.address}
              label="Address"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              value={call.machine?.city}
              label="City"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              value={call.machine?.state}
              label="State"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              value={call.machine?.zip}
              label="Zip"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <MeterCountComponent
          callid={call.id || ""}
          counts={call.machine?.meter_counts || []}
        />

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Items</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CallItemsControl
              contract={call.machine?.contract || false}
              callId={call.id as string}
              // division={call.call.division} //added by alvin
            />
          </AccordionDetails>
        </Accordion>

        <NotesComponent callid={call.id || ""} />
      </TabPanel>
      {/* <ServiceHistoryModal onClose={()=>{}} /> */}
      {/* </Paper> */}
    </>
  );
}

export default ServiceCallControl;
