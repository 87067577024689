import { Axios } from 'axios';
import { restoreAuth } from '../context/AuthProvider';
import { useAuth } from './useAuth';


export const defaultAxios = () => {
    return new Axios({
        baseURL: '/api/',
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        transformResponse: (data) => (data ? JSON.parse(data) : undefined)
    });
}

const authAxios = (token: string) => {
    const instance = defaultAxios();
    
    instance.interceptors.request.use((cfg) => {
        (cfg.headers as any).Authorization = `Bearer ${token}`;
        return cfg;
    });

    // instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // instance.defaults.headers.common['Content-Type'] = 'application/json';
    // instance.defaults.headers.common['Accept'] = 'application/json';
    return instance;
};
export const useAxios = () => {
    const { auth } = useAuth();

    //const restored = restoreAuth();

    return authAxios(auth?.token || '');
};
