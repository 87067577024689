import { Button, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useCallback, useEffect, useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useAxios } from '../hooks/useAxios';
import { ApiResponse } from '../models/ApiResponse';
import { CallItem, callItemCategory, emptyCallItem } from '../models/ServiceCall';
import CallItemModal, { callItemModalOpen } from '../pages/shared/CallItemDialog'

type Props = {
    callId: string;
    contract: boolean;
}

function CallItemsControl({ callId, contract }: Props) {
    const [items, setItems] = useState<CallItem[]|undefined>(undefined)
    const [loading, setIsLoading] = useState(false)
    const axios = useAxios();


    const fetchItems = useCallback(() => {
        setIsLoading(true)
        axios
        .get<ApiResponse<CallItem[]>>(`callitem/get?id=${callId}`)
        .then((res) => {
          const responseData = res.data;
          if (responseData.success) {
            setItems(responseData.data);
          }
        })
        .finally(() => {
            setIsLoading(false);
        });
      },[axios, callId])

    useEffect(() => {

        if (!items)
        fetchItems();

        console.log("LOADED")
    }, [fetchItems, items])

    const numberFormat = (value:number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(value); 

      
    if (loading) return <>Loading....</>

    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>&nbsp;</Grid>
          <Grid item><Button variant="contained" onClick={() => {
            callItemModalOpen(emptyCallItem(callId), contract);
          }}>Add Item</Button></Grid>
        </Grid>
        
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>TYPE</TableCell>
              <TableCell>QTY</TableCell>
              <TableCell>AMT</TableCell>
              <TableCell>DESC</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{callItemCategory(item.category)}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{numberFormat(item.amount)}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => {
                        callItemModalOpen(item, contract);
                      }}
                    >
                      <FaEdit />
                    </IconButton>
                    <IconButton aria-label="Delete" onClick={async () => {
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm("Are you sure?")) {
                           await axios.delete(`callitem/delete?id=${item.id}`)
                            alert("Done!")
                            fetchItems();
                        }
                    }}>
                      <FaTrash style={{ color: '#e15b5b'}} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <CallItemModal onClose={() => { fetchItems() }} />
      </>
    );
}

export default CallItemsControl