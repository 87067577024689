import * as React from 'react';
import { useMemo } from 'react';
import moment from 'moment';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  Alert, 
  Autocomplete, 
  Box, 
  Chip, 
  Divider, 
  FormControl, 
  FormControlLabel, 
  Grid, 
  InputLabel, 
  List, 
  ListItem, 
  ListItemText, 
  MenuItem, 
  Select, 
  Switch, 
  Tab, 
  Tabs, 
  TextField, 
  Typography  
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAxios } from '../../hooks/useAxios';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../models/ApiResponse';
import { showToast } from '../../components/Toaster';
import { 
  emptyServiceCall, 
  ServiceCall, 
  ServiceCallLog, 
  serviceCallLogActionDescription, 
  SERVICE_CALL_DIVISION_ENUM, 
  SERVICE_CALL_STATUS_ENUM, 
  SERVICE_CALL_TYPE_ENUM 
} from '../../models/ServiceCall';
import { useDataProvider } from '../../hooks/useDataProvider';
import { TabPanel } from '../../components/TabPanel';
import CallItemsControl from '../../components/CallItemsControl';
import NotesComponent from '../shared/NotesComponent';
import MeterCountComponent from '../shared/MeterCountComponent';
import { Machine, emptyMachine } from '../../models/Machine';
import { Customer, emptyCustomer } from '../../models/Customers';
import PrintCallModal, { printCallModalOpen } from '../shared/PrintCallModal';
import { emptyUser } from '../../models/User';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  root: {
    '@media print': {
      position: 'absolute',
    },
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

let serviceCallModalOpen = (call:ServiceCall) => {};
let serviceCallModalClose = () => {};

type UserDialogProps = {
    onClose?: () => void;
}

export default function CustomizedDialogs({ onClose }: UserDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [ _sc, setSc ] = React.useState<ServiceCall>(emptyServiceCall());
  const [loading, setLoading] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false)
  const [machineContract, setMachineContract] = React.useState(false)
  const [machineSelectOpen, setMachineSelectOpen] = React.useState(false);
  const [history, setHistory] = React.useState<ServiceCallLog[]>([]);
  const [holdWarn, setHoldWarn] = React.useState(false);
  const [affectedMachine, setAffectedMachine] = React.useState<Machine|undefined>(undefined)
  const [customerDivision, setCustomerDivision] = React.useState(0);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>(emptyCustomer());
  const [customerId, setCustomerId] = React.useState<string>('')
  const [equipmentIdFilter, setEquipmentIdFilter] = React.useState<string>('')
  const [customerOptions, setCustomerOptions] = React.useState<readonly Customer[]>([])
  
  const axios = useAxios();

  const data = useDataProvider();
  
  useMemo(()=> {
    if(data.customers) {
      setCustomerOptions(data.customers?.filter(c => c.division === customerDivision))
    }
  },[data.customers, customerDivision]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    if (customerId !== '' && data.customers) {
      setHoldWarn(false);
      const selected = data.customers.find(c => c.id === customerId);
      if (selected){
        if (selected.hold){
          setHoldWarn(true);
        }
        setEquipmentIdFilter("");
      }
    }

  }, [customerId, data.customers])


  const isNullOrWhiteSpace = (s:any): boolean => {
    return s === undefined || s === '' || (s as string).trim() === '' || s === null
  }

  serviceCallModalOpen = (call: ServiceCall) => {
    setTabValue(0)
    setSc(call);
    setEquipmentIdFilter('');
    setHoldWarn(false);
    setMachineContract(false);
    setHistory([])

    setIsAdd(call.id === undefined)

    if (call.machine_id) {
      const machine = data.machines?.find(m => m.id === call.machine_id);
      if (machine) {
        setCustomerId(machine.customer_id)
        setMachineContract(machine.contract)
        setAffectedMachine(machine);
      }
    } else {
      setCustomerId('')
    }

    if (call.id) {
      axios.get<ApiResponse<ServiceCallLog[]>>(`calls/logs?id=${call?.id}`)
      .then(res => {
        const api = res.data;
        setHistory(api.data);
      })
    }

    setOpen(true);

  };

  serviceCallModalClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const machineOptions = useMemo(() => {

    let results = data.machines

    if (customerId !== '') {
      results = results?.filter(m => m.customer_id === customerId)
    }

    if (equipmentIdFilter !== '') {
      const rgx = new RegExp(equipmentIdFilter, 'i')
      results = results?.filter(m =>  rgx.test(m.external_id))
    }

    return results
  },[data.machines, customerId, equipmentIdFilter])

  return (
    <div>
      <BootstrapDialog
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            return false;
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={serviceCallModalClose}
        >
          {isAdd ? "Add" : "Edit"} Service Call
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Tabs 
            value={tabValue} 
            onChange={handleChange}
            variant="fullWidth"
          >
            <Tab label="Call Details" />
            <Tab label="Call History" />
            <Tab label="Activity" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mt: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              {holdWarn && (
                <>
                  <Alert severity="warning">
                    Customer has a <b>HOLD STATUS</b> on the account.
                  </Alert>
                </>
              )}
              {machineContract && (
                <>
                  <Alert severity="success">
                    Selected Machine is under <b>CONTRACT</b>.
                  </Alert>
                </>
              )}
              <Grid container spacing={1} width={"100%"}>
                <Grid item sm={6}>
                  <InputLabel id="call-division">Division</InputLabel>
                  <Select
                    labelId="call-division"
                    value={_sc.division}
                    fullWidth
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        division: e.target.value as number,
                      });

                      setCustomerDivision(e.target.value as number)
                    }}
                  >
                    {SERVICE_CALL_DIVISION_ENUM.map((e, i) => (
                      <MenuItem key={i} value={i}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <InputLabel>PO Number</InputLabel>
                  <TextField
                    fullWidth
                    value={_sc.po_number}
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        po_number: e.target.value
                      })
                    }}
                  />
                </Grid>
                {isAdd && (
                  <>
                    <Grid item sm={6}></Grid>
                    <Grid item sm={4}>
                      <TextField
                        fullWidth
                        label="Find by Equipment id"
                        value={equipmentIdFilter}
                        onChange={(e) => {
                          setEquipmentIdFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setMachineSelectOpen(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setEquipmentIdFilter("");
                        }}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item sm={6}>
                  <InputLabel>Caller</InputLabel>
                  <TextField
                    fullWidth
                    value={_sc.caller}
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        caller: e.target.value,
                      });
                    }}
                  ></TextField>
                </Grid>
                <Grid item sm={6}>
                  <InputLabel>Caller Contact</InputLabel>
                  <TextField
                    fullWidth
                    value={_sc.caller_details}
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        caller_details: e.target.value,
                      });
                    }}
                  ></TextField>
                </Grid>
                <Grid item sm={12}>
                  <InputLabel id="call-machine-label">Customer</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerOptions}
                    value={selectedCustomer}
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select a Customer"/>}
                    isOptionEqualToValue={(option: Customer, value: Customer) => option.id === value.id}
                    onChange={(event, newValue) => {
                      setCustomerId(newValue?.id || "")
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <InputLabel id="call-machine-label">Machine</InputLabel>
                  <Select
                    labelId="call-machine-label"
                    value={_sc.machine_id}
                    fullWidth
                    open={machineSelectOpen}
                    onClose={() => setMachineSelectOpen(false)}
                    onOpen={() => setMachineSelectOpen(true)}
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        machine_id: e.target.value,
                      });

                      const machine = data.machines?.find(
                        (m) => m.id === e.target.value
                      );

                      if (machine) {
                        setMachineContract(machine.contract)
                      }

                      const customer = data.customers?.find(
                        (m) => m.id === machine?.customer_id
                      );
                      console.log(customer);
                      setCustomerId(customer?.id || "");
                    }}
                  >
                    {machineOptions?.map((mac) => (
                      <MenuItem value={mac.id} key={mac.id}>
                        <div>
                          {mac.contract ? (
                            <Chip
                              sx={{ ml: 1 }}
                              size="small"
                              color="warning"
                              label="Contract"
                            />
                          ):(<></>)}
                          <br />
                          <b>{mac.model}</b> {" "}
                          {mac.external_id !== "" && mac.external_id !== null && mac.external_id !== undefined ? (
                            <><i><u>Equipment ID:</u></i> {mac.external_id}</>
                            ):(<></>)}
                          <br />
                          <i>{mac.serial}</i>
                          <br />
                          Contract Level & Expiration: {`${mac.brand} (${mac.make})`}
                          <br />
                          address: {`${mac.address}, ${mac.city}, ${mac.state} ${mac.zip}`}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={12}>
                  <InputLabel id="description-label">Description</InputLabel>
                  <TextField
                    multiline
                    rows={5}
                    fullWidth
                    value={_sc.description}
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        description: e.target.value,
                      });
                    }}
                  ></TextField>
                </Grid>
                <Grid item sm={4}>
                  <InputLabel id="call-status">Status</InputLabel>
                  <Select
                    labelId="call-status"
                    value={_sc.status}
                    fullWidth
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        status: e.target.value as number,
                      });
                    }}
                  >
                    {SERVICE_CALL_STATUS_ENUM.map((e, i) => (
                      <MenuItem key={i} value={i}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={4}>
                  <InputLabel id="call-type">Call Type</InputLabel>
                  <Select
                    labelId="call-type"
                    value={_sc.call_type || 0}
                    fullWidth
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        call_type: e.target.value as number,
                      });
                    }}
                  >
                    {SERVICE_CALL_TYPE_ENUM.map((e, i) => (  
                      <MenuItem key={i} value={i}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={4}>
                  <InputLabel id="call-assignee">Assignee</InputLabel>
                  <Select
                    labelId="call-assignee"
                    value={_sc.assigned_to}
                    fullWidth
                    onChange={(e) => {
                      setSc({
                        ..._sc,
                        assigned_to: e.target.value,
                      });
                    }}
                  >
                    {(data.users?.filter(u => u.access_level === 0))?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item sm={3}>
                  <FormControl sx={{ m: 2 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            setSc({ ..._sc, is_callback: e.target.checked });
                          }}
                          checked={_sc.is_callback}
                        />
                      }
                      label="Callback?"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl sx={{ m: 2 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            setSc({ ..._sc, vip: e.target.checked });
                          }}
                          checked={_sc.vip}
                        />
                      }
                      label="VIP?"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box>
              <Grid container spacing={1} width={"100%"}>
                <Grid item sm={6}>
                  <Typography variant="h5">History</Typography>
                  <Divider />
                  <List>
                    {history.map((h) => (
                      <ListItem key={h.id}>
                        <ListItemText
                          primary={`${serviceCallLogActionDescription(
                            h.action
                          )}: ${h.details}`}
                          secondary={`${moment(h.created).toLocaleString()} - ${
                            h.user.name
                          }`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <MeterCountComponent
              callid={_sc.id || ""}
              counts={affectedMachine?.meter_counts || []}
            />

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Items</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CallItemsControl
                  callId={_sc.id as string}
                  contract={machineContract}
                />
              </AccordionDetails>
            </Accordion>

            <NotesComponent callid={_sc.id || ""} />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              console.log("Print Call Button Pressed");
              const serviceCall = _sc;
              const machine = data.machines?.find(m => m.id === _sc.machine_id) ?? emptyMachine(emptyCustomer().name);
              const customer = data.customers?.find(c => c.id === machine?.customer_id) ?? emptyCustomer();
              const assignee =  data.users?.find(a => a.id === _sc.assigned_to) ?? emptyUser();
              printCallModalOpen(serviceCall, machine, customer, assignee) 
            }}
            disabled={isAdd}
          >
            Print Call
          </Button>
          
          <Button
            disabled={loading}
            onClick={() => {
              if (isNullOrWhiteSpace(_sc.machine_id)) {
                alert("Please Select Machine");
                return;
              }

              if (isNullOrWhiteSpace(_sc.description)) {
                alert("Please add a description");
                return;
              }

              setLoading(true);
              axios
                .post<ApiResponse<ServiceCall>>(
                  "calls/addorupdate",
                  JSON.stringify(_sc)
                )
                .then((response) => {
                  const apiResponse = response.data;

                  if (apiResponse.success) {
                    showToast({
                      message: "Service Call saved!",
                      severity: "success",
                    });
                  } else {
                    showToast({
                      message: "An error occurred!",
                      severity: "error",
                    });
                  }
                })
                .catch((reason: AxiosError) => {
                  showToast({
                    message: `An Error occurred: ${reason.code}`,
                    severity: "error",
                  });
                })
                .finally(() => {
                  setLoading(false);
                  serviceCallModalClose();
                });
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <PrintCallModal onClose={data.fetchServiceCalls} />
    </div>
  );
}

export { serviceCallModalClose , serviceCallModalOpen }
